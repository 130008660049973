import './styles/normalize.scss'
import './styles/main.scss'
import './styles/header.scss'
import './styles/hero.scss'
import './styles/syllabus.scss'
import './styles/suits.scss'
import './styles/advantages.scss'
import './styles/price.scss'
import './styles/contact.scss'
import './styles/modal.scss'
